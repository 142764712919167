import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import moment from 'moment';
import store from '@/store';

export default function useCourseCoursesList(courseData) {
  // Use toast
  const toast = useToast();

  const refStudentsListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name' },
    {
      key: 'profiles',
      label: 'Students',
    },
    {
      key: 'createdAt',
      label: 'Created',
      formatter: (value) => {
        return moment(value).format('L');
      },
    },
    { key: 'visibility' },
    { key: 'status' },
    { key: 'actions' },
  ];
  const perPage = ref(5);
  const totalCourses = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [3, 5, 10, 15, 25];
  const searchQuery = ref('');
  const sortBy = ref('sessionDate');
  const isSortDirDesc = ref(true);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refStudentsListTable.value ? refStudentsListTable.value.localItems.length : 0;

    console.log(perPage.value, currentPage.value, localItemsCount, totalCourses.value);

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourses.value,
    };
  });

  const refetchData = () => {
    if (refStudentsListTable.value) {
      refStudentsListTable.value.refresh();
    }
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter, sortBy, isSortDirDesc], () => {
    refetchData();
  });

  const fetchProfiles = (ctx, callback) => {
    const pw = courseData;

    console.log(`fetch courses??!?`, pw);

    if (pw.profiles) {
      callback(pw.profiles.reverse());
      totalCourses.value = pw.profiles.length;
    } else {
      callback([]);
      totalCourses.value = 0;
    }
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    totalCourses,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStudentsListTable,

    fetchProfiles,
    refetchData,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
