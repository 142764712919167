import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
import store from '@/store';

export default function useClassesList() {
  // Use toast
  const toast = useToast();

  const refClassListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // { key: 'name', sortable: true },
    { key: 'date', label: 'Class Date', sortable: true },
    { key: 'time', label: 'Class Times' },
    { key: 'type', label: 'Class Type' },
    { key: 'location', label: 'Location' },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalClasses = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('date');
  const isSortDirDesc = ref(false);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refClassListTable.value ? refClassListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClasses.value,
    };
  });

  const refetchData = () => {
    refClassListTable.value.refresh();
  };
  const refCourseId = ref('');

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter, refCourseId], () => {
    refetchData();
  });

  const fetchClasses = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      // populate: 'classes,instructor'
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    console.log(pl);

    store
      .dispatch('app-roster/fetchCourseClasses', { id: refCourseId.value, queryParams: pl })
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalClasses.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching classes list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'student') return 'primary';
    if (role === 'staff') return 'warning';
    if (role === 'proctor') return 'info';
    if (role === 'accountant') return 'success';
    if (role === 'admin' || role === 'manager') return 'danger';
    return 'primary';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'student') return 'UserIcon';
    if (role === 'staff') return 'SettingsIcon';
    if (role === 'proctor') return 'BookIcon';
    if (role === 'accountant') return 'DollarSignIcon';
    if (role === 'manager') return 'Edit2Icon';
    if (role === 'admin') return 'DatabaseIcon';
    return 'UserIcon';
  };

  const resolveClassVisibilityVariant = (status) => {
    if (status === 'internal') return 'primary';
    if (status === 'public') return 'info';
    return 'primary';
  };

  const resolveClassStatusVariant = (status) => {
    if (status === 'created') return 'secondary';
    if (status === 'waiting') return 'warning';
    if (status === 'live') return 'info';
    if (status === 'completed') return 'success';
    return 'primary';
  };

  const classTypeOptions = [
    { label: 'Class', value: 'class' },
    { label: 'Lab', value: 'lab' },
  ];

  const locationOptions = [
    { label: 'Virtual', value: 'virtual' },
    { label: 'Physical', value: 'physical' },
  ];

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Waiting', value: 'waiting' },
    { label: 'Live', value: 'live' },
    { label: 'Completed', value: 'completed' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Public', value: 'public' },
  ];

  return {
    refCourseId,

    fetchClasses,
    tableColumns,
    perPage,
    currentPage,
    totalClasses,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClassListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveClassVisibilityVariant,
    resolveClassStatusVariant,
    refetchData,

    statusOptions,
    visibilityOptions,
    classTypeOptions,
    locationOptions,

    // Extra Filters
    visibilityFilter,
    statusFilter,
  };
}
