<template>
  <b-sidebar
    id="add-new-class-sidebar"
    :visible="isAddNewClassSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-class-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Add New Class</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-1" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <b-row>
            <b-col cols="12" md="12" sm="12">
              <!-- Class Date -->
              <validation-provider #default="validationContext" name="Class Date" rules="required">
                <b-form-group label="Date" label-for="class-date" :state="getValidationState(validationContext)">
                  <b-form-datepicker
                    class="w-100"
                    id="class-date"
                    :value="classData.date"
                    @input="(val) => (classData.date = val)"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="6" sm="12">
              <!-- Class Time Start -->
              <validation-provider #default="validationContext" name="Start Time" rules="required">
                <b-form-group label="Start Time" label-for="class-start-time" :state="getValidationState(validationContext)">
                  <b-form-timepicker v-model="classData.timeStart" locale="en" input-id="class-start-time" />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6" sm="12">
              <!-- Class Time End -->
              <validation-provider #default="validationContext" name="End Time" rules="required">
                <b-form-group label="End Time" label-for="class-end-time" :state="getValidationState(validationContext)">
                  <b-form-timepicker v-model="classData.timeEnd" locale="en" input-id="class-start-time" />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" sm="12">
              <!-- Class Type -->
              <validation-provider #default="validationContext" name="Class Type" rules="required">
                <b-form-group label="Type" label-for="class-type" :state="getValidationState(validationContext)">
                  <v-select
                    v-model="classData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classTypeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="class-type"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" sm="12">
              <!-- Location -->
              <validation-provider #default="validationContext" name="Location" rules="required">
                <b-form-group label="Location" label-for="location" :state="getValidationState(validationContext)">
                  <v-select
                    v-model="classData.location"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="locationOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="class-location"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" sm="12">
              <!-- Visibility -->
              <validation-provider #default="validationContext" name="Visibility" rules="required">
                <b-form-group label="Visibility" label-for="visibility" :state="getValidationState(validationContext)">
                  <v-select
                    v-model="classData.visibility"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="visibilityOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="class-visibility"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" md="12" sm="12">
              <!-- Status -->
              <validation-provider #default="validationContext" name="Status" rules="required">
                <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
                  <v-select
                    v-model="classData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="class-status"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="pt-1">
            <!-- Form Actions -->
            <b-col cols="12" md="6" sm="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2 mb-1" block type="submit">
                Add
              </b-button>
            </b-col>
            <b-col cols="12" md="6" sm="12">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                class="mb-1"
                block
                @click="hide"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormTimepicker,
  BFormDatepicker,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BRow,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import countries from '@/@fake-db/data/other/countries';
import store from '@/store';
import useClassesList from './useClassesList';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BSidebar,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewClassSidebarActive',
    event: 'update:is-add-new-class-sidebar-active',
  },
  props: {
    isAddNewClassSidebarActive: {
      type: Boolean,
      required: true,
    },
    courseId: {
      type: String,
      required: false,
    },
    editClassData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankClassData = {
      date: new Date(),
      timeStart: '09:00:00',
      timeEnd: '18:00:00',
      type: 'class',
      location: 'virtual',
      visibility: 'internal',
      status: 'created',
    };

    const origin = props.editClassData && props.editClassData.id ? props.editClassData : { ...blankClassData };
    const classData = ref({ ...origin });
    console.log(`basing data off of `, classData);

    const resetclassData = () => {
      classData.value = { ...origin };
    };

    const onSubmit = () => {
      if (props.editClassData && props.editClassData.id) {
        store
          .dispatch('app-roster/editClass', {
            id: props.editClassData.id,
            classData: classData.value,
          })
          .then(() => {
            emit('refetch-data');
            emit('update:is-add-new-class-sidebar-active', false);
            emit('update:edit-class-data', { ...blankClassData });
          });
      } else {
        store
          .dispatch('app-roster/addClass', {
            classData: { ...classData.value, courseId: props.courseId },
          })
          .then(() => {
            emit('refetch-data');
            emit('update:is-add-new-class-sidebar-active', false);
            emit('update:edit-class-data', { ...blankClassData });
          });
      }
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetclassData);

    const { statusOptions, visibilityOptions, classTypeOptions, locationOptions } = useClassesList();

    return {
      classData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      //
      statusOptions,
      visibilityOptions,
      classTypeOptions,
      locationOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-class-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
