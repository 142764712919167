<template>
  <b-card v-if="userData && userData.id" no-body class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        {{ userData.name }}
      </h5>
      <b-badge variant="light-primary text-capitalize"> Instructor </b-badge>
      <small class="text-muted w-100">@{{ userData.username }}</small>
    </b-card-header>

    <b-card-body>
      <table class="mt-1 w-100">
        <tr>
          <th class="pb-50">
            <span>Phone #</span>
          </th>
          <td class="pb-50">
            {{ userData.phoneNumber }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <span>Email</span>
          </th>
          <td class="pb-50">
            {{ userData.email }}
          </td>
        </tr>
      </table>

      <b-button class="mt-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="unassignInstructor">
        Unassign
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton, BAvatar, BRow, BLink, BMedia } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { onUnmounted, ref } from '@vue/composition-api';
import store from '@/store';
import userStoreModule from '@/views/apps/user/userStoreModule';
import router from '@/router';
import useCoursesList from '../courses-list/useCoursesList';
import { avatarText } from '@core/utils/filter';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BAvatar,
    BRow,
    BLink,
    BMedia,
  },
  props: {
    userId: {
      type: String,
    },
  },
  methods: {
    unassignInstructor() {
      this.$store
        .dispatch('app-roster/updateCourse', {
          courseId: this.$route.params.id,
          courseData: { instructor: null },
        })
        .then((response) => {
          console.log(`updated course`, response);
          this.$emit('refresh', true);
        })
        .catch((e) => {
          console.error(`failed to update course`, e);
        });
    },
  },
  setup(props) {
    const userData = ref(null);

    const COURSE_APP_STORE_MODULE_NAME = 'app-roster';
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME))
      store.registerModule(COURSE_APP_STORE_MODULE_NAME, rosterStoreModule);

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch('app-user/fetchUser', { id: props.userId })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const { resolveUserRoleVariant } = useCoursesList();

    return {
      userData,

      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>

<style></style>
