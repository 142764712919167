<template>
  <b-modal
    id="modal-lg"
    ok-title="Add Selected to Course"
    centered
    size="lg"
    title="Search for Students"
    ref="modal"
    @ok="addStudentToClass"
    @hide="$emit('update:is-add-new-student-active', false)"
  >
    <b-card no-body v-if="isSidebarActive">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @input="handleSearch"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        class="w-100"
        ref="student-table"
        mode="remote"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{ enabled: true }"
        @on-sort-change="onSortChange"
        styleClass="vgt-table bordered w-100"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <b-avatar :src="props.row.avatar" class="mx-1" />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="pageLength" :options="pages" class="mx-1" @input="handlePageChange" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                :value="currentPage"
                :total-rows="totalUsers"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store/index';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import studentStoreModule from '@/views/apps/student/studentStoreModule';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      totalPages: 0,
      totalUsers: 0,
      dir: false,
      pages: ['3', '5', '10'],
      columns: [
        {
          label: 'Student ID',
          field: 'studentId',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [],
      currentPage: 1,
    };
  },
  computed: {
    isSidebarActive() {
      return this.isAddNewStudentActive;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  props: {
    isAddNewStudentActive: {
      type: Boolean,
      default: false,
    },
    courseData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    isAddNewStudentActive: function (value) {
      console.log('update:isAddNewStudentActive', value);
      if (value) {
        this.$refs['modal'].show();
      } else {
        this.$refs['modal'].hide();
      }
    },
  },
  mounted() {
    this.fetchStudents();
  },
  methods: {
    async addStudentToClass() {
      const selected = this.$refs['student-table'].selectedRows;
      const studentIds = selected.map((student) => student.id);

      const response = await store
        .dispatch('app-roster/addStudentsToCourse', {
          courseId: this.courseData.id,
          studentIds,
        })
        .catch((error) => {
          console.error(`failed to add students to class`, error);
        });

      if (!response) return false;
      this.$emit('refreshStudents');

      console.log(`added students`, response.data);
    },

    handleSearch(searching) {
      this.log.push(`The user searched for: ${searching}`);
      this.fetchStudents();
    },
    handleChangePage(page) {
      this.log.push(`The user changed the page to: ${page}`);
      this.currentPage = page;
      this.fetchStudents();
    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`);
      this.pageLength = active;
      this.fetchStudents();
    },
    onSortChange(params) {
      this.log.push(`the user ordered:  ${params[0].type}`);
      this.fetchStudents();
    },

    fetchStudents() {
      let sortBy = 'id';
      let sbv = sortBy === 'id' ? '_id' : sortBy;
      let sb = `${sbv}:${this.dir ? 'desc' : 'asc'}`;

      store
        .dispatch('app-student/fetchStudents', {
          search: this.searchTerm,
          limit: this.pageLength,
          // sortDesc: isSortDirDesc.value,
          page: this.currentPage,
          sortBy: sb,
        })
        .then((response) => {
          const { results, totalResults } = response.data;

          console.log(response.data);

          this.rows = results;

          this.totalUsers = totalResults;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },

  setup() {
    const courseData = ref(null);

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    return { toast };
  },
};
</script>
