<template>
  <div>
    <class-list-add-new
      :is-add-new-class-sidebar-active.sync="isAddNewClassSidebarActive"
      :course-id="courseId"
      :edit-class-data.sync="editClassData"
      :visibility-options="visibilityOptions"
      :status-options="statusOptions"
      :key="editClassData.id"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button variant="primary" @click="isAddNewClassSidebarActive = true">
                <span class="text-nowrap">Add Class</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refClassListTable"
        class="position-relative"
        :items="fetchClasses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No classes assigned to this course"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Class -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'apps-roster-class-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ (data.item.classes || []).length }} total classes</small>
        </template>

        <!-- Column: Date of Class -->
        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ moment(data.item.date).utc().format('MMMM Do YYYY (dddd)') }}
            </span>
          </div>
        </template>

        <!-- Column: Live Classes -->
        <template #cell(time)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ moment(data.item.timeStart, 'HH:mm:ss').format('hh:mm A') }}
              -
              {{ moment(data.item.timeEnd, 'HH:mm:ss').format('hh:mm A') }}
            </span>
          </div>
        </template>

        <!-- Column: Live Classes -->
        <template #cell(live_classes)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.classes.filter((clazz) => clazz.status === 'live').length
            }}</span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(type)="data">
          <b-badge pill :variant="`light-${data.item.type === 'class' ? 'success' : 'warning'}`" class="text-capitalize">
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Visibility -->
        <template #cell(location)="data">
          <b-badge
            pill
            :variant="`light-${data.item.location === 'virtual' ? 'primary' : 'secondary'}`"
            class="text-capitalize"
          >
            {{ data.item.location }}
          </b-badge>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge pill :variant="`light-${resolveClassVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveClassStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="() => doClassEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="() => doClassDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalClasses"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ClassListFilters from './ClassListFilters.vue';
import useClassesList from './useClassesList';
import rosterStoreModule from '../rosterStoreModule';
import ClassListAddNew from './ClassListAddNew.vue';
import moment from 'moment';

export default {
  components: {
    ClassListFilters,
    ClassListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  props: {
    courseId: {
      type: String,
      required: false,
    },
  },
  methods: {
    doClassEdit(existingClassData) {
      console.log(`starting edit for`, existingClassData);
      this.editClassData = existingClassData;
      this.isAddNewClassSidebarActive = true;
    },

    doClassDelete(classData) {
      if (!classData || !classData.id) {
        // TODO: add alert that cannot delete
        return;
      }

      store
        .dispatch('app-roster/deleteClass', { id: classData.id })
        .then(() => {
          // TODO: Add alert that class was deleted and removed from course (if applicable)
          this.refetchData();
        })
        .catch((error) => {
          // TODO: Add error dialog
        });
    },
  },
  setup(props) {
    const CLASS_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(CLASS_APP_STORE_MODULE_NAME)) store.registerModule(CLASS_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLASS_APP_STORE_MODULE_NAME)) store.unregisterModule(CLASS_APP_STORE_MODULE_NAME);
    });

    const isAddNewClassSidebarActive = ref(false);
    const editClassData = ref({});

    const {
      fetchClasses,
      tableColumns,
      perPage,
      currentPage,
      totalClasses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClassListTable,
      refetchData,

      statusOptions,
      visibilityOptions,

      refCourseId,

      // UI
      resolveUserRoleVariant,
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    } = useClassesList();

    if (props.courseId) {
      refCourseId.value = props.courseId;
    }

    return {
      // Sidebar
      isAddNewClassSidebarActive,
      editClassData,

      fetchClasses,
      tableColumns,
      perPage,
      currentPage,
      totalClasses,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClassListTable,
      refetchData,
      refCourseId,

      moment,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,

      visibilityOptions,
      statusOptions,

      // Extra Filters
      visibilityFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
